<template>
<div class="app app-container clearfix">
    <div class="bananr"></div>
    <div class="common-content content">
        <div class="common-title">
            <p>新闻中心</p>
            <span></span>
        </div>
        <el-row class="newsList">
            <el-row class="list" v-for="(list,index) in news" :key="'n_' + index" @click.native="toNews(list)">
                <el-col :span="4">
                    <img :src="list.cover" alt="">
                </el-col>
                <el-col :span="20" class="wap_right">
                    <el-row>
                        <el-col :span="24"><h2>{{list.title}}</h2></el-col>
                        <el-col :span="20" class="des">{{list.digest}}</el-col>
                        <el-col :span="3" class="right">
                            <p class="time">{{list.day}}</p>
                            <p>{{list.yue}}</p>
                        </el-col>
                        <el-col :span="1">
                            <i class="el-icon-arrow-right"></i>
                        </el-col>
                    </el-row>
                </el-col>
                <!-- <el-col :span="2"></el-col> -->
            </el-row>
        </el-row>
        <div class="block">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="total">
            </el-pagination>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name:'NewsList',
    data(){
        return{
           // 新闻列表
            news: [],
            total:0,
            params:{
                page:1,
                pageSize:10
            }  
        }
    },
    watch:{
        '$route':{
            handler(val){
                this.getNews('', this.params);
            },
            immediate:true
        }
    },
    methods:{
        // 获取首页新闻列表数据
        getNews (url, params) {
            this.$store.dispatch('getNews', {url:url, params:params}).then(res => {
                if (res.data.length > 0) {
                    res.data.forEach(item => {
                        item.timeDay = item.pub_date.split('-');
                        item.day = item.timeDay[1] + '-' + item.timeDay[2];
                        item.yue = item.timeDay[0] ;
                        this.news.push(item)
                    })
                }
                this.total = res.total;
            })
        },
        handleCurrentChange(val) {
            //console.log(`当前页: ${val}`);
            this.params.page = val;
            this.getNews('', this.params);
        },
        toNews(data){
            console.log(data, 'data=======');
            this.$router.push({path:'/news/' + data.id})
        }
    }
}
</script>
<style scoped lang='less'>
.bananr{
    width:100%;
    height: 400px;
    background:url("../assets/images/new_bananr.png") top center no-repeat;
    background-size: auto 100%;
}
.common-title p{
    margin:0 auto;
}
.newsList{
    width:100%;
    div.list{
        background: #FFFFFF;
        border: 1px solid #EAEAEA;
        padding:15px;
        color: #666666;
        font-size: 16px;
        margin-bottom: 20px;
        text-align: left;
        line-height: 30px;
        cursor: pointer;
        img{
            max-width: 90%;
        }
        h2{
            font-weight: bold;
            color: #FF9900;
            line-height: 30px;
            font-size: 20px;
            text-align: left;
            margin:0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .des{
            color:rgba(102, 102, 102, 1);
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .time{
            color:rgba(34, 34, 34, 1);
            font-size: 20px;
            font-weight: bold;
        }
        p{
            margin:0;
        }
        .right{
            border-left:1px solid #eaeaea;
            padding-left:15px;
            text-align: center;
        }
        .el-col-1{
            text-align: center;
            .el-icon-arrow-right{
                cursor: pointer;
                line-height: 60px;
            }
        }
    }
}
::v-deep{
    .el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #ff9900;
        border-bottom:#ff9900
    }
}
@media screen and (max-width: 767px){
    .bananr{
        height: 160px;
    }
    .common-title{
        margin:30px 0;
        p{
            font-size:20px;
        }
    }
    .newsList{
        width:90%; margin:0 auto;
        div.list{
            .el-col-4{
                width:100%;
                img{
                    text-align: center;
                    max-width: 100%;
                }
            }
            .wap_right{
              width:100%;
              .el-col-20{
                  width:70%;
              }
              .el-col-3{
                  width:calc(30% - 4.16667%)
              }
            }
            h2{
                font-size:15px;
            }
            .des{
                font-size:14px;
                line-height: 1.7;

            }
            .time{
                font-size:14px;
            }
        }
    }
}
</style>